/* eslint-disable import/prefer-default-export */
import { store } from '@redux/store'
import { setStoreCartCreationData } from '@redux/modules/cart'
import { getOrder, setOrderField } from '@helpers/checkout/global'
import { setEquickDecision } from '@helpers/checkout/payment-section/rtg-finance'
import { setContactInfo, setShippingAddressInfo } from '@helpers/checkout/shipping-section'
import { addStoreCartEvent } from '@helpers/google-tag-manager'
import { getFromBrowserStorage } from '@helpers/storage'
import { getStoreCart } from '@services/checkout'

/**
 * Checks if fetched store cart is valid. If not, returns false. If it is, then:
 * - updates the contact, shippingAddress, and storeInfo fields on the Order object in redux and sessionStorage with data from the store cart,
 * - sets storeCartCreationData value in redux so the store cart will be imported by Cart,
 * - pushes GA event for loading the store cart, then
 * - returns true
 * @param {object} fetchedStoreCart
 * @param {boolean} didMoveCartItemsToFavorites if true, will cause StoreCartModal to display on Cart page
 * @returns {boolean}
 */
export const insertStoreCartDetails = (fetchedStoreCart, didMoveCartItemsToFavorites = false) => {
  const { storeCartArray } = fetchedStoreCart

  // If fetchedStoreCart includes property storeCartArray, then take the latest cart in the array, else use fetchedStoreCart
  const storeCart =
    storeCartArray && storeCartArray.length ? storeCartArray[storeCartArray.length - 1] : { ...fetchedStoreCart }

  const isValidStoreCart = Array.isArray(storeCart?.lineItems) && storeCart?.lineItems.length > 0

  if (isValidStoreCart) {
    const {
      contact,
      quickScreenInfo,
      shippingAddress,
      salesPersonInfo,
      storeAddress,
      storeCartId,
      storeName,
      storeNumber,
      storePhoneNumber,
    } = storeCart

    if (contact && shippingAddress) {
      setContactInfo(contact)
      setShippingAddressInfo(shippingAddress)

      const storeInfo = { salesPersonInfo, storeAddress, storeCartId, storeName, storeNumber, storePhoneNumber }
      setOrderField('storeInfo', storeInfo)
    }

    if (quickScreenInfo) {
      const order = getOrder()
      const { financePlan = {} } = order
      const { creditLimit, decision, offerExpiryDate, synchronyEapplyUrl } = quickScreenInfo
      const isPrequalified =
        ['APPROVED', 'CREDIT_APPROVED'].includes(decision) && !!creditLimit && !!offerExpiryDate && !!synchronyEapplyUrl

      setOrderField('financePlan', { ...financePlan, isPrequalified, quickScreenInfo })
    }

    store.dispatch(
      setStoreCartCreationData({ id: storeCartId, email: contact?.email ?? '', didMoveCartItemsToFavorites }),
    )
    addStoreCartEvent(storeCart)
  }

  return isValidStoreCart
}

/**
 * Uses the storeCart.contact.email and storeInfo.storeCartId fields from the order object in sessionStorage to call the
 * storeCartByEmail API endpoint to reload a previously imported store cart or to confirm status of Synchrony pre-approval
 * offer. If the checksum value in the response has changed, then loads the updated store cart.
 * @param {boolean} [isUpdatingSynchronyDecision] optional boolean - set to true to check Sychrony decision, false or omit to reload storecart
 * @returns if isUpdatingSynchronyDecision then returns null and updates order.financePlan, else returns updated storeCart object
 */
export const reloadStoreCart = async (isUpdatingSynchronyDecision = false) => {
  const { storeCart = {}, storeInfo = {} } = getFromBrowserStorage('session', 'order') ?? {}

  const checksum = storeCart?.checksum ?? ''
  const email = storeCart?.contact?.email ?? ''
  const storeCartId = storeInfo?.storeCartId ?? ''

  if (storeCartId && email) {
    const fetchedStoreCart = await getStoreCart({ email, storeCartId })

    if (isUpdatingSynchronyDecision) {
      const { quickScreenInfo = {} } = fetchedStoreCart ?? {}
      setEquickDecision(quickScreenInfo?.decision)
      return null
    }

    if (checksum !== fetchedStoreCart?.checksum) {
      // only refresh store cart if the checksum has changed, which indicates the cart was updated by the sales person
      insertStoreCartDetails(fetchedStoreCart)
    }
  }
  return null
}
