import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useDispatch } from 'react-redux'
import { useFirebaseContext } from '@context/firebaseContext'
import { getURLParam } from '@helpers/general'
import { analyticsProduct, addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { getFromBrowserStorage } from '@helpers/storage'
import { insertStoreCartDetails } from '@helpers/store-cart'
import usePageAnalytics from '@hooks/usePageAnalytics'
import { clearCart } from '@redux/modules/cart'
import { getStoreCart } from '@services/checkout'
import useStore from '@store'
import { identifyUser } from '@components/integrations/Segment'
import { UNABLE_TO_FIND_STORE_CART, FILLOUT_REQUIRED_FIELDS } from './messages'
import InputPageContent from './input-page-content'

const StoreToCart = () => {
  const [storeCartId, setStoreCartId] = useState('')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { user } = useFirebaseContext()
  const addFavorite = useStore(store => store.favorites.add)
  const dispatch = useDispatch()

  const handleInputChange = ({ target }) => {
    if (target.name === 'store-cart-id') {
      setStoreCartId(target.value)
    }
    if (target.name === 'email') {
      setEmail(target.value)
    }
    if (errorMessage) setErrorMessage('')
  }

  const handleSubmit = async (submittedId, submittedEmail) => {
    if (!submittedId || !submittedEmail) {
      setErrorMessage(FILLOUT_REQUIRED_FIELDS)
    } else {
      if (errorMessage) setErrorMessage('')

      // Segment Identify call
      identifyUser({ email: submittedEmail, signup: 'false', subsourcecode: 'rtgastorecart' })

      try {
        // async request for the storecart data from the backend
        setLoading(true)
        const { cartItems } = getFromBrowserStorage('local', 'cart') ?? { cartItems: [] }
        const hasOnlineCartItems = cartItems?.length > 0
        const storeCart = await getStoreCart({ storeCartId: submittedId, email: submittedEmail })
        const isValid = insertStoreCartDetails(storeCart, hasOnlineCartItems)

        if (isValid) {
          // If there are existing Cart Items, move them to Favorites before navigating to Cart
          if (hasOnlineCartItems) {
            cartItems
              ?.map(item => item?.product ?? {})
              ?.filter(item => !item?.isConfigurable && item?.sku !== '83333333') // Gift Cards and Modular Configured items cannot be Favorited
              ?.forEach((item, index) => {
                const trackingData = {
                  ecommerce: {
                    add_to_wishlist: {
                      position: index,
                      list: 'search',
                      products: [analyticsProduct(item)],
                      auth_user_id: user?.uid || null,
                    },
                  },
                }
                addToDataLayer_nextgen('ee_add_to_wishlist', trackingData)
                addFavorite(user)(item?.sku)
              })
            dispatch(clearCart())
          }
          navigate('/cart')
        } else {
          setLoading(false)
          setErrorMessage('There was an issue with your Store Cart')
        }
      } catch {
        setLoading(false)
        setErrorMessage(UNABLE_TO_FIND_STORE_CART)
      }
    }
  }

  usePageAnalytics(
    { type: 'store-cart-input-page', title: 'Store Cart Input Page', path: '/store-cart-input-page' },
    null,
    null,
  )

  useEffect(() => {
    const cartNumberFromUrl = getURLParam('storeCartNo')
    const emailAddressFromUrl = getURLParam('emailAddress')
    if (cartNumberFromUrl) setStoreCartId(cartNumberFromUrl)
    if (emailAddressFromUrl) setEmail(emailAddressFromUrl)

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' })
    }
    if (cartNumberFromUrl && emailAddressFromUrl) {
      // If URL contains store cart ID and email address, the skip the input and immediately validate
      handleSubmit(cartNumberFromUrl, emailAddressFromUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InputPageContent
      email={email}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={loading}
      storeCartId={storeCartId}
    />
  )
}

export default StoreToCart
